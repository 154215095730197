<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Sekeleton from "../../../components/widgets/loadingtable";
import Download from "../../../components/widgets/download.vue";
import Helper from "../../../api/helper";
import ImageView from "../../../components/widgets/imageView.vue";
import VideoView from "../../../components/widgets/videoView.vue";
import AudioView from "../../../components/widgets/audioView.vue";
import belowNav from "../../../components/belowNavbar.vue";
export default {
  page: {
    title: "Share With Me",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,Sekeleton,Download,ImageView,VideoView,AudioView,belowNav },
  data() {
    return {
      title: "Share With Me",
      sortName : false,
      counter : 0,
      showProperties : false,
      itemT : {isFolder:false}
    }
  },
  computed: {
    state(){
        return this.$store.getters['state/state'];
    },
    listings(){
        return this.$store.getters['share/items'];
    },
    showFile(){
        return this.$store.getters['share/showFile'];
    },
    loading(){
        return this.$store.getters['share/loading'];
    },
    item() {
        return this.$store.getters['share/item']
    },
    myEmail() {
        return this.$store.getters['user/email']
    },
    grid(){
        return this.$store.getters['storage/gridView'];
    },
  },
  watch:{
    // eslint-disable-next-line no-unused-vars
    showFile(nw,old){// eslint-disable-next-line no-unused-vars
        if(nw){
          this.openFile(this.$store.getters['share/shared'])
        }
    }
  },
  mounted(){
    // this.$ga.page('/share')
    this.$ga.event('share-page', 'actionx', 'labelx', 1)
    this.$store.commit(this.state+"/SET_ALL_SELECTED",[]);
    this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
    this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
    const sideBarValue = this.$store.getters['storage/sideRightToggle'];
    this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: true});
    if(this.$store.getters["upload/files"].length > 0){
      document.getElementById("uploadLoading").style.display = "block";
    }else{
      document.getElementById("uploadLoading").style.display = "none";
    }
    this.$store.dispatch("share/listing");
    this.$store.commit("storage/SET_SHOWBREADCUMB",false);
    this.$store.commit("storage/SET_SUBTITLE","Share With Me");
  },
  methods:{
      detailToggle2(item){
        const sideBarValue = this.$store.getters['storage/sideRightToggle'];
        var x = document.getElementById('show-details-info-sideRight')
        if(!sideBarValue){
          this.$store.dispatch('storage/setSideRightToggle',{isShowing: true, isPersistent: false})
          // if(x.style.display === 'none'){
          //   x.style.display = 'block'
          // }
          this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
        }else{
          // if(x.style.display === 'none'){
          //   x.style.display = 'block'
          // }
          this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
        }
        this.properties(item)
      },
      clickers(item){
        
        this.counter++
        
        if (this.counter === 1) {
          this.clickTimer = setTimeout(() => {
            this.counter = 0
            this.properties(item)
          }, 300)
        } else if (this.counter === 2 && (item.file[0].encrypt != 2 && item.file[0].encrypt != 7 && item.file[0].encrypt != 4)) {
          clearTimeout(this.clickTimer)
          this.counter = 0
          this.openFile(item)
        }
    
      },
      properties(list){
        if(list == null){list = this.itemT}
        this.showProperties = true
        this.$store.commit("share/SET_ITEM",list);
        this.$store.commit("share/SET_SHOW_PROPERTIES",true);
        // var header = document.getElementById("page-topbar");
        // header.style.right = "400px"; 
        var mainpage = document.getElementById("main-page");
        mainpage.style.marginRight = "410px";
        this.hideContextMenu()
      },
      doubleClick(item){
        if(item.file[0].encrypt != 2 && item.file[0].encrypt != 7){

          this.openFile(item);
        }
      },
      openFile(list){
        if(list == null){list = this.itemT}
        if(Helper.supportOnlyOffice(list.file[0].extension)){
            var indexMe = list.users_id.find((v)=>v.email == this.myEmail);
            if(indexMe.access_type == null){
              this.$store.commit("error/SET_MESSAGE","you can't access this file anymore");
              this.$store.commit("error/SET_ERROR",true);
            }
            localStorage.setItem("access_type", JSON.stringify(indexMe.access_type.toLowerCase()))
            this.$store.dispatch("storage/open",list.file[0]);
        }else if(Helper.isImages(list.file[0].extension)){
            this.$store.dispatch("storage/getLinkFile",list.file[0]);
            this.$store.dispatch("storage/fileretrieve",{_id : list.file[0]._id,directory:list.file[0].directory});
              let imageView = document.getElementById("imageView");
              imageView.style.display = 'block';
        }else if(Helper.isVideos(list.file[0].extension)){
            this.$store.dispatch("storage/fileretrieve",{_id : list.file[0]._id,directory:list.file[0].directory})
            .then(c=>{
              let video = document.getElementById("VideoView");
              video.style.display = 'block';
              this.$store.dispatch("storage/getLinkFile",c)
              .then((v)=>{
                let videoPlayer = document.getElementById("videoPlayer");
                videoPlayer.src = v;
                videoPlayer.play();
              });
            });
        }else if(Helper.isAudios(list.file[0].extension)){
            this.$store.dispatch("storage/fileretrieve",{_id : list.file[0]._id,directory:list.file[0].directory})
            .then(c=>{
              let audio = document.getElementById("AudioView");
              audio.style.display = 'block';
              this.$store.dispatch("storage/getLinkFile",c)
              .then((v)=>{
                let audioPlayer = document.getElementById("audioPlayer");
                audioPlayer.src = v;
                audioPlayer.play();
              });
            });
        }else{
          this.$store.dispatch("storage/download",
          {
              directory :  list.file[0].directory,
              hash_name :  list.file[0].hash_name,
              file_name :  list.file[0].file_name
          }
          );
        }
      },
      openDownload(item){
        if(item == null){item=this.itemT}
        this.$store.commit("storage/SET_ITEM",item.file[0]);
        this.$store.dispatch("storage/fileretrieve",{_id : item.file[0]._id,directory:item.file[0].directory})
        this.$root.$emit('bv::show::modal', 'modalDownload');
      },
      righClick(item){
        this.itemT = item;
        var menu = document.getElementById("contextMenu");
        var field = document.getElementById(item._id+"item");
        menu.style.display = 'block';
        menu.style.right = null;
        menu.style.left = field.offsetLeft+field.offsetWidth-60+"px";
        menu.style.bottom = null;
        menu.style.top = field.offsetTop +60+ "px";
      },
      hideContextMenu(){
        var menu = document.getElementById("contextMenu");
        menu.style.display = 'none';
        menu.style.top = 0;
      }
  }
};
</script>

<template>
  <Layout>
    <belowNav/>
    <Download/>
    <ImageView/>
    <VideoView/>
    <AudioView/>
    <!-- start page title -->
    <!-- <div class="row"> -->
      <div  class="col-12 all-pages">
        <div class="page-title-box d-flex align-items-center justify-content-between"  v-if="!grid">
            <table class="table table-storage default-text-style">
                <thead>
                    <th width="55%" class="default-text-style">
                        <span class="text-muted">Name</span>
                    </th>
                    <th width="23%" class="default-text-style table-web-display">
                        <span class="text-muted">Last Modified</span>
                    </th>
                    <th width="20%" class="default-text-style table-web-display">
                        <span class="text-muted">File Size</span>
                    </th>
                    <th width="2%"></th>
                </thead>
                <Sekeleton v-if="loading"/>
                <tbody v-if="listings.length > 0 && !loading">
                    <tr v-for="(list,index) in listings" :key="index">
                        <!-- <td class="align-items-center"  @dblclick="(counter += 1), doubleClick(list)"> -->
                        <td class="align-items-center"  @click="clickers(list)">
                          <div class="td-file">
                            <div class="d-flex align-items-center">
                                <img v-if="list.file[0].encrypt == 2 || list.file[0].encrypt == 7 || list.file[0].encrypt == 4" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                <img v-else :src="require(`@/assets/images/icon/${list.file[0].icon}`)" alt />
                                <span v-b-tooltip.hover.ds1000.top="list.file[0].file_name"  class="mx-2 file-name">{{list.file[0].file_name}}</span>
                            </div>
                            <div class="icon-status-file">
                                <img v-if="list.file[0].encrypt == 2 || list.file[0].encrypt == 7 || list.file[0].encrypt == 4" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-2">
                            </div>
                          </div>
                        </td>
                        <td  class="table-web-display">
                           <p> {{list.file[0].datemodified}}</p>
                        </td>
                        <td  class="table-web-display">
                            <p>{{list.file[0].size}}</p>
                        </td>
                        <td>
                            <div class="more-btn">
                                <b-dropdown  dropleft class="bg-transparent more-menu">
                                    <template #button-content>
                                        <img src="@/assets/images/icon/More.png" alt />
                                    </template>
                                    <!-- <b-dropdown-item-button v-if="!list.file[0].isFolder" @click="openFile(list)">
                                        <img src="@/assets/images/icon/Move.png" alt height="100%" />
                                        <span class="ml-3">Open</span>
                                    </b-dropdown-item-button> -->
                                    <b-dropdown-item-button  v-if="!list.file.isFolder" @click="openDownload(list)" >
                                        <img src="@/assets/images/icon/fi-rr-download.png" alt height="100%" />
                                        <span class="ml-3">Download</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="detailToggle2(list)">
                                        <img src="@/assets/images/icon/fi-rr-info.png" alt height="100%" />
                                        <span class="ml-3">Details</span>
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

<!-- grid -->
        <div class="page-title-box d-flex justify-content-start align-items-start grid-view" id="container-file" v-else>
          <table class="table mt-4 table-storage default-text-style " sticky-header>
            <thead>
                <th width="64%" class="default-text-style" colspan="3"> 
                      <span class="text-muted">Name </span>
                </th>
                <th width="5%"></th>
            </thead>
          </table>
          <div class="d-flex justify-content-start align-items-start grid-view" v-if="loading">
            <div class="card dip-card-item m-2" v-for="loop in 18" :key="loop">
              <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <div class="mt-4"></div>
                <b-skeleton-img card-img="left" width="72px" height="72px"></b-skeleton-img>
                <div class="mt-2"></div>
                <b-skeleton width="100px"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-start grid-view"  v-if="listings.length > 0 && !loading">
            <div  :class="`card dip-card-item m-2`" 
                v-for="(list,index) in listings" 
                :key="index" 
                :id="list._id+'item'" 
                style="height: 164px; width: 168px"
                @contextmenu.prevent="righClick(list)"
                v-on:click.exact="hideContextMenu"  
                @dblclick="(counter += 1), doubleClick(list)"
                v-click-outside="hideContextMenu"
                >
              <div class="card-body">
                <div class="mt-4"></div>
                <div :id="index+'card'" class="d-flex flex-column justify-content-center align-items-center">
                  <div class="mb-1">
                    <img
                      v-if="list.file[0].encrypt == 2 || list.file[0].encrypt == 7"
                      class="lock-md"
                      src="@/assets/images/icon/lock-encrypt.svg"
                      alt
                    />
                    <img
                      v-else
                      class="avatar-md"
                      :src="require(`@/assets/images/icon/${list.file[0].icon}`)"
                      alt
                    />
                  </div>
                  <h5 class="default-text-style elipse-text">
                    <div class="d-flex">
                      <div class="elipse-text">
                        <a
                          href="javascript: void(0);"
                          class="text-dark "
                          >{{ list.file[0].file_name }}
                        </a>
                      </div>
                      <img
                        v-if="list.file[0].encrypt == 2 || list.file[0].encrypt == 7"
                        class=""
                        v-b-tooltip.hover
                        title="This file is encrypted"
                        src="@/assets/images/icon/encrypted-file.svg"
                        alt
                      />
                    </div>
                  </h5>
                </div>
                <b-tooltip :target="index+'card'" placement="top" :delay="{show:800,hide:100}">
                  {{ list.file[0].file_name }}
                </b-tooltip>
              </div>
            </div> 
          </div>
          <b-list-group id="contextMenu">
            <b-list-group-item button @click="openFile(null)">
              <img src="@/assets/images/icon/fi-rr-expand.png" alt height="100%" />
              <span class="ml-3 default-text-style">Open</span>
            </b-list-group-item>
            <b-list-group-item button @click="openDownload(null)">
              <img src="@/assets/images/icon/fi-rr-download.png" alt height="100%" />
              <span class="ml-3 default-text-style">Download</span>
            </b-list-group-item>
            <b-list-group-item button @click="detailToggle2(null)">
                <img src="@/assets/images/icon/fi-rr-info.png" alt height="100%" />
                <span class="ml-3 default-text-style">Details</span>
            </b-list-group-item>
          </b-list-group>
        </div>
<!-- end grid -->

        <div v-if="listings.length <= 0 && !loading" class="d-flex flex-column justify-content-center align-items-center">
            <span class="mt-2"></span><br>
            <img src="@/assets/images/empty-shared.svg" alt class="img-fit mb-4" />
            <p class="mb-0 pb-0 title-empty mb-2">No files shared with you</p>
            <p class="text-empty pt-0 mt-0">Files others have shared with you will be shown here.</p>
        </div>
      </div>
    <!-- </div> -->
    <!-- end page title -->
  </Layout>
</template>
